<template>
    <el-dialog
    v-model="dialogVisible"
    title="评估详情"
    width="1000px"
    >
    <div class="mainPage">
        <div class="containTitle">
            <div class="title">相似案例</div>
            <div class="titleLine"></div>
        </div>
        <div class="containBox">
            <div v-for="(item, index) in resultDecision" :key="index">
                <div class="itemCase">
                    <div class="titleCase">案例{{ index + 1 }}</div>
                    <div style="width: 100%;padding: 20px; box-sizing: border-box;">
                        <div>调控措施：{{ item.controlPlan }}</div>
                        <div>案例描述：{{ item.description }}</div>
                        <div>相似度：{{ Math.round(item.similarity) }}%</div>
                    </div>
                </div>
            </div>
            <div v-if="resData">{{ resData }}</div>
        </div>
        <div class="containTitle">
            <div class="title">调控方案</div>
            <div class="titleLine"></div>
        </div>
        <div class="containBox">
            <div v-for="(item, index) in makeFun" :key="index">
                <div class="itemCase">
                    <div class="titleCase">{{ item.name }}</div>
                    <div style="width: 100%;padding: 20px; box-sizing: border-box;">
                        <div v-for="(i, jindex) in item.data" :key="jindex">
                            {{jindex+1}}：{{ i }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="makeFun.length==0">
                无
            </div>
        </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { site_caseAndControl } from '@/api/site-warning.js'
export default{
    components:{

    },
    data(){
        return{
            dialogVisible:false,
            resultDecision:[],
            makeFun:[],
            resData:null,
        }
    },
    mounted(){
    },
    methods:{
        open(data){
            this.resultDecision = []
            this.makeFun = []
            this.resData = null
            this.dialogVisible = true
            this.getDetils(data)
        },
        getDetils(data){
            site_caseAndControl(data).then(r=>{
                let decision = r.result
                if(decision){
                    let data = decision.resultDecision
                    if(Array.isArray(data)){
                        this.resultDecision = data
                    }else{
                        this.resData = data
                        return
                    }
                    for(let key in decision){
                        if(key!='resultDecision'){
                            this.makeFun.push({
                                name:key,
                                data:decision[key]
                            })
                        }
                    }
                }
            })
        },
        changeOk(val){
            this.radio = val
            this.chartValue = this.senerData[this.radio].map(i=>{return i.envirParamValue})
            this.chartTime = this.senerData[this.radio].map(i=>{return i.collectTime})
            let sener =  this.senerS.find(v => v.id === val)
            this.chartUnit = `${sener.label}(${sener.unit})`
        },
    }
}
</script>
<style lang="scss" scoped>
.mainPage{
    width: 100%;
    min-height: 500px;
    margin:  0 auto;
    background-color: white;
    box-sizing: border-box;
    .containTitle{
        display: flex;
        justify-content: flex-start;
        .titleLine{
            height: 30px;
            width: calc(100% - 70px);
            box-sizing: border-box;
            border-bottom: 1px solid rgb(173, 173, 173);
        }
        .title{
            width: 70px;
            line-height: 30px;
            box-sizing: border-box;
            color: rgb(32, 72, 252);
            height: 30px;
            border-bottom: 2px solid rgb(32, 72, 252);
            font-weight: bold;
        }
    }
    .containBox{
        margin-top: 20px;
        line-height: 30px;
        .itemCase{
            width: 100%;
            border: 1px solid rgb(157, 157, 157);
            box-sizing: border-box;
            margin-bottom: 20px;
            .titleCase{
                line-height: 30px;
                font-size: 16px;
                text-align: center;
                border-bottom: 1px solid rgb(157, 157, 157);
                box-sizing: border-box;
                background-color: rgba($color: #1d94d3, $alpha: 1.0);
                color: white;
            }
        }
    }
}
.chartBox{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .chart{
        width: 100%;
        height: 400px;
    }
}
.lineCss{
    display: flex;
    justify-content: flex-start;
}
</style>