import request from '@/utils/request'

export function site_ScoreOverview(data) { //环境风险预警占比概览
  return request({
    url: '/site/data/queryScoreOverview',
    method: 'get',
    params:data
  })
}

export function site_scoreDetail(data) { //环境风险预警占比概览
    return request({
      url: '/site/data/scoreDetail',
      method: 'get',
      params:data
    })
  }

  export function site_queryRelic(data) { //根据展柜id和风险码获取文物列表
    return request({
      url:'/site/data/queryRelic',
      method: 'get',
      params:data
    })
  }  

  export function site_alarms(data) { //预警列表
    return request({
      url:'/site/data/alarms',
      method: 'get',
      params:data
    })
  }

  export function site_updateAlarm(data) { //更新病害、处理预警
    return request({
      url:'/site/data/updateAlarm',
      method: 'PUT',
      data
    })
  }
  
  export function site_listExceedRatio(data) { //风险超限时长占比
    return request({
      url:'/site/data/listExceedRatio',
      method: 'get',
      params:data
    })
  }
  
  export function site_queryRelicRisk(data) { //根据文物id查询风险信息
    return request({
      url:'/site/data/queryRelicRisk',
      method: 'get',
      params:data
    })
  }

  export function site_queryCase(data) { //检索相似案例
    return request({
      url:'/site/data/queryCase',
      method: 'get',
      params:data
    })
  }

  export function site_controlCase(data) { //辅助决策
    return request({
      url:'/site/data/controlCase',
      method: 'get',
      params:data
    })
  }

  //===========new==========
  export function site_list(data) { //评估环境列表
    return request({
      url:'/site/data/env/list',
      method: 'get',
      params:data
    })
  }

  export function site_listAdd(data) { //评估环境新增
    return request({
      url:'/site/data/env/add',
      method: 'post',
      data
    })
  }
  export function site_listEdit(data) { //评估环境编辑
    return request({
      url:'/site/data/env/update',
      method: 'put',
      data
    })
  }
  export function site_listDel(id) { //评估环境删除
    return request({
      url:`/site/data/env/del?id=${id}`,
      method: 'delete',
    })
  }
  

  export function site_relics(data) { //文物列表
    return request({
      url:`/site/data/relic/list`,
      method: 'get',
      params:data
    })
  }

  export function site_relicAdd(data) { //文物新增
    return request({
      url:`/site/data/relic/add`,
      method: 'post',
      data
    })
  }

  export function site_relicEval(data) { //文物评估
    return request({
      url:`/site/data/relic/eval`,
      method: 'post',
      data
    })
  }
 
  export function site_queryByIdEval(id) { //评估详情
    return request({
      url:`/site/data/eval/queryById?id=${id}`,
      method: 'get',
    })
  }
 
  export function site_listEval(id) { //评估列表
    return request({
      url:`/site/data/eval/list?relicid=${id}`,
      method: 'get',
    })
  }

  export function site_queryAlarmCount(data) { //报警统计
    return request({
      url: `/site/data/queryAlarmCount`,
      method: 'get',
      params:data
    })
  }


  export function site_caseAndControl(data) { //在线风险评估
    return request({
      url: `/site/data/caseAndControl`,
      method: 'get',
      params:data
    })
  }

