<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      color: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      data: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      Time:{
        type: Array,
        default: ()=>{
          return []
        }
      },
      unit:{
        type:String,
        default:'',
      }
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'Time':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
          let _this = this
          this.chart.setOption({
            dataset: [
                {
                source: this.data
                },
                {
                transform: {
                    type: 'boxplot',
                    config: { 
                        itemNameFormatter:function (params) {
                         return _this.Time[params.value]  
                        } 
                    }
                }
                },
                {
                fromDatasetIndex: 1,
                fromTransformResult: 1
                }
            ],
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                },
                
            },
            grid: {
                left: '10%',
                right: '10%',
                bottom: '15%'
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                nameGap: 30,
                splitArea: {
                show: false
                },
                splitLine: {
                show: false
                }
            },
            yAxis: {
                scale:true,
                type: 'value',
                name: this.unit,
                splitArea: {
                show: true
                }
            },
            series: [
                {
                    name: this.unit,
                    type: 'boxplot',
                    datasetIndex: 1,
                    tooltip: {
                        formatter:(param)=>{
                            return [
                                param.name + ': ',
                                '最小值: ' + param.data[1],
                                'Q1: ' + param.data[2],
                                '平均值: ' + param.data[3],
                                'Q3: ' + param.data[4],
                                '最大值: ' + param.data[5]
                            ].join('<br/>')
                        }
                    }
                },
            ]
          })    
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>