<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      color: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      series: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      yAxis:{
        default: ()=>{
          return []
        }
      },
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'series':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
          let _this = this
          this.chart.setOption({
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
                type: 'time',
                boundaryGap: false
            },
            yAxis:this.yAxis,
            series: this.series
          })    
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>