<template>
    <div class="pageBox">
        <div class="leftBox">
            <div class="operBar">
                <el-select v-model="build" @change="changeBuild" placeholder="楼栋" >
                    <el-option
                    v-for="item in builds"
                    :key="item.envId"
                    :label="item.envName"
                    :value="item.envId"
                    />
                </el-select>
                <el-select v-model="statu" clearable class="marginCss" placeholder="风险状态">
                    <el-option
                    v-for="item in status"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
            <div>
                <el-tree-v2 
                ref="treeRef"
                class="filter-tree"
                node-key="envId"
                :height="800"
                highlight-current
                :data="treeData" 
                :expand-on-click-node="false"
                :props="defaultProps" 
                @current-change="nodeChange"
                />
            </div>
        </div>
        <div class="rightBox">
            <div class="RB-top">
                <div class="RBT-Left">
                    <div class="titleBox titleCss">
                        <div>
                            <img src="../../assets/siteImg/title.png">
                            文物保存环境风险时间信息
                        </div>
                        <div>
                            <el-select v-model="relic" @change="relicChange" placeholder="请选择">
                                <el-option
                                v-for="item in relics"
                                :key="item.relicid"
                                :label="item.culturalName"
                                :value="item.relicid"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="titleMsgBox boxPadding">
                        <el-descriptions title="" :column="2" border v-if="relicData">
                            <el-descriptions-item label="文物质地">{{ relicData.material }}</el-descriptions-item>
                            <el-descriptions-item label="发生时间">{{ relicData.createtime }}</el-descriptions-item>
                            <el-descriptions-item label="发生地点">{{ relicData.positionname }}</el-descriptions-item>
                            <el-descriptions-item label="文物级别">{{ relicData.level }}</el-descriptions-item>
                            <el-descriptions-item label="文物本体病害">{{ relicData.disease }}</el-descriptions-item>
                            <el-descriptions-item label="风险等级">{{ relicData.sensorstate }}</el-descriptions-item>
                            <el-descriptions-item label="风险源">{{ relicData.cause }}</el-descriptions-item>
                            <el-descriptions-item label="展藏状态">{{ relicData.sensorstate }}</el-descriptions-item>
                        </el-descriptions>
                        <el-empty v-else description="暂无信息" />
                        <div class="boxBtn" v-if="relicData?true:false">
                            <el-button  type="primary" @click="caseFun" >方案评估</el-button>
                        </div>
                    </div>
                </div>
                <div class="RBT-Right">
                    <div class="titleBox titleCss">
                        <div>
                            <img src="../../assets/siteImg/title.png">
                            文物保存状态环境评定
                        </div>
                        <div>
                            <el-date-picker
                            style="width:300px"
                            v-model="TimeRang"
                            @change="TimeChange"
                            :clearable="false"
                            type="daterange"
                            range-separator="-"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            />
                        </div>
                    </div>
                    <div class="titleMsgBox">
                        <div class="chartBox">
                            <lineChart :series="scoreline" :yAxis="scoreyAxis" />
                        </div>
                        <div class="chartBoxBottom">
                            <div class="chartBoxBar">
                                <el-radio-group v-model="senerType" @change="senerChange" :disabled="envId?false:true">
                                    <el-radio-button label="01">湿度</el-radio-button>
                                    <el-radio-button label="02">温度</el-radio-button>
                                    <el-radio-button label="03">二氧化碳</el-radio-button>
                                    <el-radio-button label="04">光照</el-radio-button>
                                    <el-radio-button label="05">紫外辐射强度</el-radio-button>
                                </el-radio-group>
                            </div>
                            <div class="chartBoxMini">
                                <boxPlot :Time="boxTime" :data="boxData" :unit="baseData[senerType].envirParamName+'('+baseData[senerType].envirDataUnit+')'"/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="RB-bottom">
                <div class="titleBox titleCss">
                    <div>
                        <img src="../../assets/siteImg/title.png">
                        环境综合评估报告
                    </div>
                    <div class="titleCss">
                        <el-date-picker
                        style="width:360px;margin-right: 10px;"
                        v-model="reportTimeRang"
                        :clearable="false"
                        type="datetimerange"
                        range-separator="-"
                        unlink-panels
                        format="YYYY-MM-DD HH:mm:ss"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        /> 
                        <el-button type="primary" @click="ReportClick(0)">查询</el-button>
                        <el-button type="primary" plain @click="creatReport">我要评估</el-button>
                    </div>
                </div>
                <div class="titleMsgBox boxPadding">
                    <el-table :data="TableList" border style="width: 100%">
                    <el-table-column prop="envname" label="报告环境" />
                    <el-table-column label="报告日期">
                        <template #default="scoped">
                            {{ scoped.row.start }} ~ {{ scoped.row.end }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="报告名称"/>
                    <el-table-column prop="createuser" label="创建用户"/>
                    <el-table-column prop="createtime" label="创建时间"/>
                    <el-table-column label="报告状态">
                        <template #default="scoped">
                            <el-button type="primary" loading v-if="scoped.row.status==0" link>生成中</el-button>
                            <el-button type="success" link v-if="scoped.row.status==1">生成成功</el-button>
                            <el-button type="danger" link  v-if="scoped.row.status==2">生成失败</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template #default="scope">
                            <el-button type="primary" :disabled="scope.row.status!=1" @click="preview(0,scope.row.report)" link>预览</el-button>
                            <el-button type="primary" :disabled="scope.row.status!=1" @click="preview(1,scope.row.report)"  link>下载</el-button>
                            <el-button type="danger" :disabled="scope.row.status==0" @click="Del(scope.row.id)"  link>删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    class="pagination"
                    small
                    layout="total, prev, pager, next"
                    :total="total"
                    :page-size="query.size"
                    :current-page="query.page"
                    @current-change="CurrentChange"
                />
                </div> 
            </div>
        </div>
        <caseForm ref="caseForm"/>
        <reportCreat ref="reportAdd" @back="ReportClick"/>
    </div>
</template>
<script>
import lineChart from '@/components/charts/lineChart.vue'
import boxPlot from '@/components/charts/boxplot.vue'
import { site_envList, site_queryScore, site_listDeviceData1 } from '@/api/site-home.js'
import { site_reports, site_delReports } from'@/api/site-dataAnalysis.js'
import reportCreat from './modules/reportCreat.vue'
import { site_queryRelic, site_queryRelicRisk, site_queryCase, site_controlCase } from '@/api/site-warning.js'
import { mapGetters } from 'vuex'
import moment from 'moment'
import caseForm from './modules/operForm.vue'
export default{
    components:{
        lineChart,
        boxPlot,
        caseForm,
        reportCreat
    },
    computed:{
        ...mapGetters(['siteId'])
    },
    data(){
        return{
            envId:null, //微环境ID
            build:null,
            builds:[],
            statu:null,
            status:[
                {label:'优', value:'0'},
                {label:'良', value:'1'},
                {label:'中', value:'2'},
                {label:'差', value:'3'}
            ],
            defaultProps:{
                value: 'envId',
                children: 'children',
                label: 'envName',
            },
            treeData:[],
            relic:null,
            relics:[],
            TimeRang:[moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            scoreline:[{
                name: '风险评分',
                type: 'line',
                smooth: true,
                symbol: 'none',
                areaStyle:{},
                data: [],
            }],
            scoreyAxis:[
                {
                    name: '风险评分',
                    type: 'value',
                    min:0,
                    max:100,
                },
            ],
            senerType:'01',
            boxTime:[],
            boxData:[],
            baseData:{
                "01": {
                "envirDataUnit": "%",
                "envirParamName": "湿度",
                "envirParamCode": "humidity",
                "envirDataPrecision": "",
                "envirParamType": "01"
                },
                "02": {
                "envirDataUnit": "℃",
                "envirParamName": "温度",
                "envirParamCode": "temperature",
                "envirDataPrecision": "",
                "envirParamType": "02"
                },
                "03": {
                "envirDataUnit": "ppm",
                "envirParamName": "二氧化碳",
                "envirParamCode": "co2",
                "envirDataPrecision": "",
                "envirParamType": "03"
                },
                "04": {
                "envirDataUnit": "lx",
                "envirParamName": "光照度",
                "envirParamCode": "light",
                "envirDataPrecision": "",
                "envirParamType": "04"
                },
                "05": {
                "envirDataUnit": "μw/cm2",
                "envirParamName": "紫外",
                "envirParamCode": "uv",
                "envirDataPrecision": "",
                "envirParamType": "05"
                },
            },
            relicData:null,
            caseData:[],
            activeNames:0,
            caseType:0,

            reportTimeRang:[moment().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')],
            TableList:[],
            query:{
                page:1,
                size:10,
                start:null,
                end:null,
            },
            total:0,
            
        } 
    },
    mounted(){
        this.GetBuild()
        this.ReportClick(0)
    },
    methods:{
        moment,
        nodeChange(data, node){
            if(data.envType==51||data.envType==52||data.envType==53){
                let query = {
                    envid:data.envId,
                    riskCode:this.statu
                }
                this.envId = data.envId
                this.relicData = null
                site_queryRelic(query).then(r=>{
                    this.relics = r.data
                    if(this.relics.length>0){
                        this.relicChange(this.relics[0].relicid)
                    }else{
                        this.relic = null
                        
                    }
                })
                this.Get_site_queryScore()
            }
        },
        ReportClick(type){
            if(type == 0){
                this.query.siteid = this.siteId
                this.query.page = 1
            }
            if(this.reportTimeRang){
                this.query.start = this.reportTimeRang[0]
                this.query.end = this.reportTimeRang[1]
            }else{
                this.query.start = null
                this.query.end = null
            }
            
            site_reports(this.query).then(r=>{
                this.TableList = r.data.data
                this.total = r.data.pageInfo.total
            }) 
        },
        CurrentChange(page){
            this.query.page = page
            this.ReportClick(1)
        },
        creatReport(){
            this.$refs.reportAdd.open()
        },
        Del(id){
            this.$confirm(
                '此操作将永久删除该报告，是否继续?',
                '删除',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            ).then(() => {
                site_delReports(id).then(r=>{
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.ReportClick(0)
                })
            })
            .catch(() => {
                
            })
        },
        preview(type, src){ //预览报告
            if(type == 0){
                let path = src.split(".")
                path[path.length-1] = 'pdf'
                path = path.join('.')
                window.open(path, '_blank')
            }else{
                window.open(src, '_blank')
            }
           
        },
        relicChange(val){
            this.relic = val
            site_queryRelicRisk({relicid:this.relic}).then(r=>{
                let alarm = r.data.alarm
                let relicrisk = r.data.relicrisk
                if(!relicrisk){
                    return
                }
                let relicdetail = JSON.parse(relicrisk.relicdetail)
                this.relicData = {
                    disease:alarm.disease,
                    material:alarm.material,
                    positionname:alarm.positionname,
                    cause:alarm.cause,
                    createtime:alarm.createtime,
                    sensorstate:relicrisk.sensorstate=='0'?'优':relicrisk.sensorstate=='1'?'良':relicrisk.sensorstate=='2'?'中':'差',
                    sensorstateText:relicrisk.sensorstate=='0'?'0':relicrisk.sensorstate=='1'?'1':relicrisk.sensorstate=='2'?'2':'3',
                    level:relicdetail.relicLevel,
                    museumName:relicdetail.museumName,
                    detail:alarm.detail,
                    relicname:alarm.relicname,
                    relicid:alarm.relicid,
                    position:alarm.position,
                    score:relicrisk.score
                }
            })
        },
        TimeChange(val){
            this.TimeRang = val
            this.Get_site_queryScore()
        },
        senerChange(val){
            this.senerType = val
            let Query = {
                envid:this.envId,
                start:this.TimeRang[0],
                end:this.TimeRang[1],
                deviceType:this.senerType
            }
            site_listDeviceData1(Query).then(r=>{
                this.boxTime = r.data.map(i=>{
                    return i.daytime
                })
                this.boxData = r.data.map(i=>{
                    return [i.max, i.avg,  i.min]
                })
            })
        },
        makeData(){
            let base = +new Date(2023, 7, 1);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 50]];
            for (let i = 1; i < 100; i++) {
                let now = new Date((base += oneDay));
                data.push([+now, Math.round((Math.random() - 0) * 50 )]);
            }
            return data
        }, 
        GetBuild(){
            let query = {
                'siteId':this.siteId,
                'envId':this.siteId,
                'isSelf':false,
                'isTree':true
            }
            site_envList(query).then(r=>{
                this.builds = r.data
                this.changeBuild(r.data[0].envId)
            })
        },
        changeBuild(val){
            this.build = val
            let query = {
                'siteId':this.siteId,
                'envId':val,
                'isSelf':true,
                'isTree':true
            }
            this.relicData =null
            site_envList(query).then(r=>{
                this.treeData = r.data
            })
        },
        Get_site_queryScore(){
            if(!this.TimeRang){
                this.$message.warning('请补充查询条件')
                return
            }
            let Query = {
                envid:this.envId,
                start:this.TimeRang[0],
                end:this.TimeRang[1],
                deviceType:this.senerType
            }
            site_queryScore(Query).then(r=>{
                this.scoreline[0].data = r.data.map(i=>{
                    return [i.day, i.score]
                })
            })
            site_listDeviceData1(Query).then(r=>{
                this.boxTime = r.data.map(i=>{
                    return i.daytime
                })
                this.boxData = r.data.map(i=>{
                    return [i.max, i.avg,  i.min]
                })
            })
        },
        queryCase(type){
            let query = {
                envid:this.relicData.position,
                sitename:this.relicData.museumName,
                relicid:this.relicData.relicid,
                description:`${this.relicData.relicname}（${this.relicData.material}），处于${this.relicData.sensorstateText}级风险，${this.relicData.detail}，检查发现${this.relicData.disease||'没有出现病害'}`,
                locationName:this.relicData.positionname
            }
            this.caseType = type
            if(type == 0){
                site_queryCase(query).then(r=>{
                    let data = JSON.parse(r.data).result
                    this.caseData = data.caseData
                })
            }else{
                site_controlCase(query).then(r=>{
                    let data = JSON.parse(r.data).result
                    let controlPlanData = data.controlPlanData
                    let objArr = []
                    for(let key in controlPlanData){
                        objArr.push({typeName:key, arr:controlPlanData[key]})
                    }
                    this.caseData = objArr

                })
            }
            
        },
        caseFun(){
            let query = {
                envid:this.relicData.position,
                sitename:this.relicData.museumName,
                texture:this.relicData.material,
                culturalName:this.relicData.relicname,
                description:`${this.relicData.relicname}（${this.relicData.material}），处于${this.relicData.sensorstateText}级风险，${this.relicData.detail}，检查发现${this.relicData.disease||'没有出现病害'}`,
                score:this.relicData.score
            }
            this.$refs.caseForm.open(query)
        }
    },    
}
</script>
<style lang="scss" scoped>
.boxPadding{
    padding: 20px;
    box-sizing: border-box;
    .boxBtn{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
}
.titleMsgBox{
    width: 100%;
    height: calc(100% - 60px);
}

.titleCss{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.titleBox{
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #CFCFCF;
    box-sizing: border-box;
    padding:0px 20px;
    margin-bottom: 10px;
    img{
        width: 20px;
        height: 30px;
        object-fit: contain;
        vertical-align: middle;
    }
}
.pageBox{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    display:flex;
    justify-content: space-between;
}
.leftBox{   
    width:360px;
    border-radius: 10px 10px 0px 0px;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    .operBar{
        display: flex;
        justify-content: space-between;
        align-items:center;
        .marginCss{
            margin-left: 20px;
        }
    }
    
}
.rightBox{
    width: calc(100% - 380px);
}
.RB-top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .RBT-Left{
        width: calc(60% - 20px);
        height: 600px;
        background-color: white;
        border-radius: 10px 10px 0px 0px;

    }
    .RBT-Right{
        width: 40%;
        height: 600px;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        .chartBox{
            width: 100%;
            height: 45%;
        }
        .chartBoxBottom{
            width: 100%;
            height: 55%;
        }
        .chartBoxBar{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }
        .chartBoxMini{
            width: 100%;
            height: calc(100% - 40px);
        }
    }
}
.RB-bottom{
    width: 100%;
    margin-top: 20px;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    min-height: 500px;
    .planTitles{
        border-bottom: 1px solid #cac8c8;
        line-height: 30px;
    }
    .planBox{
        width: 100%;
        height: calc(100% - 30px);
    }
}
.marginCss{
    margin-left: 20px;
}

    .caseBox{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .case{
            margin-left:20px;
            margin-top: 20px;
            border: 1px solid rgb(186, 186, 186);
            .caseTitle{
                border-bottom: 1px solid rgb(186, 186, 186);
                height: 30px;
                line-height: 30px;
                text-align: center;
            }
            .caseItem{
                padding: 20px;
               
            }
        }
    }
  
 /deep/.el-collapse-item__header{
    background-color: #dfdfdf;
 }   

 .pagination{
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

</style>